<template>
    
    <div class="row justify-center">
      <h4>Qui sommes-nous ?</h4>
    </div>

    <div class="row justify-center">
      <div class="col-xs-10 col-md-6">

        <h5 class="indent">
          C’est quoi Mandragore ?
        </h5>

        <div class="regular-text indent">
          Mandragore, c’est une communauté d’amis avant tout. Ce sont des gens qui aiment se retrouver pour jouer, rigoler et passer du bon temps ensemble. Ce sont des gens qui ont voulu, à un moment, créer un endroit qui dépassait ce qu’apporte traditionnellement une guilde de jeu vidéo, et où le principal critère de recrutement se résumerait à “est-ce que la personne derrière le joueur est vraiment cool ?”. Mandragore, c’est la réunion de personnes ayant tous un gros point commun : Jouer. Beaucoup, longtemps, partout, entre nous, avec les amis faits en chemin.
          <br><br>Puisant son origine et ses racines dans World of Warcraft, c’est aujourd’hui la principale activité de la communauté. Mandragore et WoW, c’est une longue histoire qui a commencé à Wrath of the Lich King et qui continue encore aujourd’hui, écrite par ses membres qui ont envie d’apporter leur pierre à l’édifice. Il n’est toutefois pas obligatoire de jouer à WoW pour nous rejoindre, puisque nous nous sommes déjà étendus sur d’autres supports, et avons toujours souhaité continuer à développer nos liens sur tous les supports qui sont mis à notre disposition. Que ce soit FF14, les autres jeux Blizzard, les derniers jeux à la mode, et même le petit jeu obscur méconnu,  si vous aimez un jeu, vous avez 100% de chance de tomber sur un Mandragore qui l’aime aussi !
          <br><br>Mandragore, c’est aussi des gens qui se voient autant qu’ils le peuvent en-dehors de WoW et des autres jeux : c’est une grosse IRL annuelle vers le milieu de l’année, et de plus en plus de petites IRL ponctuelles qui peuvent s’organiser partout en France et dans le monde.
        </div>

        <div class="parchemin" style="margin-top: 60px;">
          <div class="row justify-center oregano-regular">
            <h5>
              Ça vient d’où Mandragore ?
            </h5>

            <div class="col-md-10 regular-text indent oregano-regular" style="padding-bottom: 60px;">
              Mandragore, c’est à la base plusieurs joueurs de WoW, venant tous du serveur Kael’thas, éparpillés dans différentes guildes mais qui prenaient plaisir à se retrouver et jouer ensemble depuis plus de deux ans, pour faire des contenus inhabituels, des vieux raids, etc. D’abord regroupés dans un simple canal de discussion en jeu, ses participants adhèraient de moins en moins à l’ambiance de leurs guildes respectives et du manque de ce côté humain. C’est donc en l’an de grâce 2009, sous la houlette de Kehyll et Shantaram, qu’ils décidèrent de créer officiellement la guilde Mandragore. 
              <br><br>
            </div>
            <div class="col-md-10 regular-text indent oregano-regular" style="padding-bottom: 60px;">
              Le critère de recrutement était simple : la personne avant le personnage. Si la personne est sympa, on aura envie de l’avoir dans notre entourage. Si la personne est antipathique et désagréable, même en étant forte au jeu, elle n’aura pas sa place chez nous. Rapidement, la guilde grandit et s’étoffe de joueurs qui ont vu en Mandragore ce qui leur manquait dans le jeu : un lieu de paix et de bienveillance où ils peuvent jouer avec des gens qu’ils apprécient.
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="row justify-center" style="margin-top: 40px; margin-bottom: 30px;">
      <div class="justify-center">

      <router-link clas="col-6" to="/association/fonctionnement">
        <q-btn color="primary" label="Le fonctionnement&nbsp;&nbsp;&nbsp;►" />
      </router-link>

    </div>
  </div>  

</template>  


<style scoped>

.indent {
  text-indent: 50px;
}

.parchemin {
  background-image: url("../../../public/img/parchemin_coupe.png");
  background-size: 100% 100%; /* Stretch the image to cover the entire div */
  background-repeat: no-repeat;
  background-position: center;
  width: 100%; /* Make the div take up the full width of its container */
  min-height: 200px; /* Set a minimum height for the div */
  padding: 20px; /* Add padding to ensure text isn't right against the edges */
  box-sizing: border-box; /* Ensure padding is included in the width/height calculation */
}

.oregano-regular {
  font-family: "Oregano", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}

</style>